.custom-tooltip {
    position: relative;
    cursor: pointer;
  }
  
  .custom-tooltip:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
  }
  
  .tooltip-content {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    text-align: left;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    max-width: 175px;
    max-height: 100px;
    z-index: 1;
    transition: opacity 0.3s;
    display: flex;
    flex-direction: column; /* Each item in a new line */
  }
  
  .tooltip-content::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
  